import React from "react"
import Helmet from "react-helmet"

import SEO from "../components/SEO"
import ArrowLink from "../components/ArrowLink"

import "./404.scss"

const NotFoundPage = (location) => {
    return (
        <div>
            <SEO title="404: Page not found" description="Not all those who wander are lost." />
            <Helmet
                bodyAttributes={{
                    class: "notFoundPage",
                }}
                key="helmet"
            />
            <div className="pageContent">
                <h1 className="pageTitle">404: Page not found</h1>
                <h2 className="h1">Page not found</h2>
                <p className="h3">Not all those who wander are lost.</p>
                <p className="homeLink">
                    <ArrowLink from="404" text={"Head back home"} href={"/"} />
                </p>
            </div>
        </div>
    )
}

export default NotFoundPage
