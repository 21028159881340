import React from "react"

import "./Arrow.scss"

const Arrow = () => (
    <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 10">
        <path d="M12.25.06l7,4.5a.5.5,0,0,1,.07.82l-.07.06-7,4.5a.51.51,0,0,1-.69-.19.5.5,0,0,1,.12-.63l.07-.06L17.09,5.5H.5a.5.5,0,0,1-.09-1H17.09L11.75.94a.5.5,0,0,1-.22-.6l0-.09a.5.5,0,0,1,.6-.22Z" />
    </svg>
)

export default Arrow
