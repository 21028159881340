import React from "react"

import "./LoadingIndicator.scss"

interface Props {
    loading: boolean
    style?: React.CSSProperties
}

const LoadingIndicator = ({ loading = true, style }: Props) =>
    loading ? (
        <svg style={style} className="loadingIndicator" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57">
            {/* By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL */}

            <circle fill="white" cx="5" cy="50" r="5">
                <animate
                    attributeName="cy"
                    begin="0s"
                    dur="2.2s"
                    values="50;5;50;50"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="cx"
                    begin="0s"
                    dur="2.2s"
                    values="5;27;49;5"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
            </circle>
            <circle fill="white" cx="27" cy="5" r="5">
                <animate
                    attributeName="cy"
                    begin="0s"
                    dur="2.2s"
                    from="5"
                    to="5"
                    values="5;50;50;5"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="cx"
                    begin="0s"
                    dur="2.2s"
                    from="27"
                    to="27"
                    values="27;49;5;27"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
            </circle>
            <circle fill="white" cx="49" cy="50" r="5">
                <animate
                    attributeName="cy"
                    begin="0s"
                    dur="2.2s"
                    values="50;50;5;50"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="cx"
                    from="49"
                    to="49"
                    begin="0s"
                    dur="2.2s"
                    values="49;5;27;49"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    ) : (
        <div style={{ width: 20, height: 20 }}></div>
    )

export default LoadingIndicator
