import React from "react"
import _ from "lodash"

import ArrowText from "./ArrowText"
import FadeLink from "./FadeLink"

import "./ArrowLink.scss"

const ArrowLink = ({ text, from, href, state }) => (
    <FadeLink className="arrowLink" to={href} state={state} from={from}>
        <ArrowText text={text} />
    </FadeLink>
)

export default ArrowLink
