import React from "react"

import Arrow from "../components/Arrow"

import "./ArrowText.scss"
import LoadingIndicator from "./LoadingIndicator"

const ArrowText = ({ text, loading = false }) => (
    <div className="arrowText">
        {text}
        {loading ? <LoadingIndicator style={{ marginLeft: 8 }} isProcessing={true} /> : <Arrow />}
    </div>
)

export default ArrowText
